<template>
  <bumps-template
    :comedones="comedones"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import BumpsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-texture/bumps/BumpsTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no } from '@/modules/questionnaire/api/helpers';

const fieldsToReset = ['comedoLocations', 'otherComedoLocations', 'textureDiscomfort'];

const { field, requiredField } = fieldBuilder;

const FIELDS = [...fieldsToReset.map(field), requiredField('comedones')];

export default {
  name: 'Bumps',
  components: {
    BumpsTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    comedones(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToReset);
      }

      this.showNextStep();
    }
  }
};
</script>
